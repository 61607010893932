<template>
  <b-container fluid>
       <b-row style="line-height: 20px">
            <template>
                <b-row>
                    <b-col md="12">
                        <table style="line-height: 25px" class="table table-sm table-borderless">
                            <tr>
                                <th style="width: 40%">{{$t('globalTrans.country_name')}}</th>
                                <td colspan="3" style="width: 85%">: {{ getCountryName(items.country_id) }} </td>
                            </tr>
                            <tr>
                                <th style="width: 40%">{{$t('tradeTariffApp.tariff_year')}}</th>
                                <td colspan="3" style="width: 85%">: {{ items.tariff_year | dateFormat }} </td>
                            </tr>
                            <tr>
                                <th style="width: 40%">{{$t('tradeTariffApp.mfn_country')}}</th>
                                <td colspan="3" style="width: 85%">: {{ getCountryName(items.mfn_country) }} </td>
                            </tr>
                            <tr>
                                <th style="width: 40%">{{$t('tradeTariffApp.avg_mfn_tariff_rate')}}</th>
                                <td colspan="3" style="width: 85%">: {{ $n(items.mfn) }} </td>
                            </tr>
                            <tr>
                                <th style="width: 40%">{{$t('tradeTariffApp.wto_agri')}}</th>
                                <td colspan="3" style="width: 85%">: {{ $n(items.wto_agri) }} </td>
                            </tr>
                            <tr>
                                <th style="width: 40%">{{$t('tradeTariffApp.wto_indus')}}</th>
                                <td colspan="3" style="width: 85%">: {{ $n(items.wto_indus) }} </td>
                            </tr>
                        </table>
                    </b-col>
                </b-row>
            </template>
    </b-row>
  </b-container>
</template>
<script>
import { tradeTariffServiceBaseUrl } from '@/config/api_config'
export default {
    props: ['items'],
    components: {
    },
    data () {
        return {
            tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        activeStatusList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Active' : 'সক্রিয়' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Inactive' : 'নিষ্ক্রিয়' }
                ]
            return list
        }
    },
    methods: {
    activeStatus (sta) {
          const reqStatusObj = this.activeStatusList.find(tlaw => tlaw.value === parseInt(sta))
          return reqStatusObj.text
        },
    getCountryName (id) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.find(item => item.status === 1 && item.value === parseInt(id))
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            } else {
                return ''
            }
        }
}
}

</script>
