<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Country Name" vid="country_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="country_id"
                slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('globalTrans.country')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  v-model="form.country_id"
                  :options="tradeTariffCountryList"
                  id="country_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="MFN Country" vid="mfn_country" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="mfn_country"
                slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('tradeTariffApp.mfn_country')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  v-model="form.mfn_country"
                  :options="tradeTariffCountryListx"
                  id="mfn_country"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Avg MFN Tariff Rate" vid="mfn" rules="required|min:1|max:20">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="mfn"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('tradeTariffApp.avg_mfn_tariff_rate')}}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="form.mfn"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  id="mfn"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
              <ValidationProvider name="Tariff Year" vid="tariff_year" rules="required">
                <b-form-group
                  label-for="tariff_year"
                  slot-scope="{ valid, errors }"
                  label-cols-sm="4"
                >
                <template v-slot:label>
                  {{ $t('tradeTariffApp.tariff_year') }}
                </template>
                <b-form-select
                  plain
                  v-model="form.tariff_year"
                  :options="yearList"
                  id="tariff_year"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Avg Tariff on WTO Agriculture Product" vid="wto_agri" rules="required|min:1|max:20">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="wto_agri"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('tradeTariffApp.wto_agri')}}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="form.wto_agri"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  id="wto_agri"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Avg Tariff on WTO Industrial Product" vid="wto_indus" rules="required|min:1|max:20">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="wto_indus"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('tradeTariffApp.wto_indus')}}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="form.wto_indus"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  id="wto_indus"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { tariffProfileManagementStore, tariffProfileManagementUpdate } from '../../api/routes'

export default {
  name: 'Form',
  props: ['id', 'secretQuestionList'],
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      unitLoading: false,
      form: {
        tariff_year: 0,
        country_id: 0,
        mfn_country: 0,
        mfn: '',
        wto_agri: '',
        wto_indus: '',
        status: 1
      },
      exportStatusList: [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Active' : 'সক্রিয়' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Inactive' : 'নিষ্ক্রিয়' }
      ],
      capitalInfoList: []
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getEditInfo()
      this.form = tmp
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    tradeTariffCountryList: function () {
      return this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.filter(item => item.status === 1)
    },
    tradeTariffCountryListx: function () {
      return this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.filter(item => item.status === 1 && item.value !== this.form.country_id)
    },

    passwordRules: function () {
      return this.id ? '' : 'required|min:6'
    },
    passwordConfirmRules: function () {
      return this.id ? '' : 'required|min:6'
    },
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.$n(item, { useGrouping: false }) }
        } else {
          return { value: item, text: item }
        }
      })
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localizeSeaPortsList(newVal)
      }
    },
    'form.country_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.capitalInfoList = this.getCapitalInfoList(newVal)
      }
    }
  },
  methods: {
    getPorts () {
      this.getPortsList(this.form.country_id)
    },
    getCapitalInfoList (countryId) {
      const p = this.$store.state.TradeTariffService.commonObj.capitalInfoList.filter(offType => offType.country_name_id === parseInt(countryId))
      if (p.length > 0) {
        this.form.capital_id = p.find(offType => offType.country_name_id === parseInt(countryId)).value
      } else {
        this.form.capital_id = 0
      }
      return p
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(tradeTariffServiceBaseUrl, `${tariffProfileManagementUpdate}/${this.id}`, this.form)
      } else {
        result = await RestApi.postData(tradeTariffServiceBaseUrl, tariffProfileManagementStore, this.form)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getEditInfo () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
